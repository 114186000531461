import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";

import { createValidUrl } from "../../../utils/commonFuctions";

export const getBase64OfHTMLDivComponentAsPDF = async (
  divId,
  breakDivId,
  config,
) => {
  try {
    const gridElement = document.getElementById(divId);
    let group;
    if (gridElement) {
      group = await drawDOM(
        gridElement,
        config || {
          paperSize: "A4",
          margin: "0.5cm",
          scale: 0.7,
        },
      );
    }
    if (breakDivId && group) {
      const breakElement = document.getElementById(breakDivId);

      if (breakElement) {
        const breakGroup = await drawDOM(
          breakElement,
          config || {
            paperSize: "A4",
            margin: "0.5cm",
            scale: 0.7,
          },
        );
        group.children.push(breakGroup);
      }
    }
    if (group) {
      const base64 = await exportPDF(group);
      return base64;
    }
    return "";
  } catch (error) {
    throw error;
  }
};

export const downloadBase64File = (base64, fileName) => {
  const pdfData = base64.substring(base64.indexOf(",") + 1);
  if (pdfData) {
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isChrome =
      navigator.userAgent.toLowerCase().indexOf("CriOS") > -1 ||
      navigator.vendor.toLowerCase().indexOf("google") > -1;
    let iOSVersion = [];
    if (iOS) {
      iOSVersion = navigator.userAgent
        .match(/OS [\d_]+/i)[0]
        .substring(3)
        .split("_")
        .map((n) => parseInt(n));
    }
    const attachmentData = pdfData;
    const attachmentName = fileName;
    let contentType = "application/pdf";

    const binary = window.atob(attachmentData.replace(/\s/g, ""));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i += 1) {
      view[i] = binary.charCodeAt(i);
    }
    const linkElement = document.createElement("a");
    try {
      let hrefUrl = "";
      let blob = "";
      if (iOS && !isChrome && iOSVersion[0] <= 12) {
        blob = `data:application/pdf;base64,${pdfData}`;
        hrefUrl = blob;
      } else {
        if (iOS && !isChrome) {
          contentType = "application/octet-stream";
        }
        blob = new Blob([view], { type: contentType });
        hrefUrl = window.URL.createObjectURL(blob);
      }
      linkElement.setAttribute("href", hrefUrl);
      linkElement.setAttribute("target", "_self");
      if ((iOS && (iOSVersion[0] > 12 || isChrome)) || !iOS) {
        linkElement.setAttribute("download", attachmentName);
      }
      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      linkElement.dispatchEvent(clickEvent);
    } catch (ex) {}
  }
};

export const addExtraPercentageAmount = (value, percentage) => {
  const result = (percentage * value) / 100;
  return result;
};
export const getTextColor = (color) => {
  const rgb = hexToRgb(color);
  const brightness = (rgb?.r * 299 + rgb?.g * 587 + rgb?.b * 114) / 1000;
  return brightness > 128 ? "#000000" : "#FFFFFF";
};

export const hexToRgb = (hex) => {
  hex = hex?.replace(/^#/, "");
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  const a = bigint / 255;

  return { r, g, b, a };
};

export const exportElement = (element, options, fileName = "esport") => {
  drawDOM(element, options)
    .then((group) => exportPDF(group))
    .then((dataUri) => {
      saveAs(dataUri, fileName);
    });
};

export const processDescription = ({
  desc,
  removeBold,
  addLineBreak,
  mergeSpan,
}) => {
  if (removeBold) {
    desc = desc.replace(/<strong>/g, "").replace(/<strong\/>/g, "");
  }
  if (addLineBreak) {
    desc = desc.replace(/\n/g, "<br/>");
  }
  if (mergeSpan) {
    desc = desc
      .replace(/<h[1-6]/g, "<p")
      .replace(/<\/h[1-6]\/>/g, "</p>")
      .replace(/<div/g, "<p")
      .replace(/<\/div\/>/g, "</p>");
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = desc;
    tempDiv.style.display = "none";
    const paragraphs = tempDiv.querySelectorAll("p");
    const combinedTexts = [];

    paragraphs.forEach((paragraph) => {
      const spans = paragraph.querySelectorAll("span");

      if (spans.length > 0) {
        let combinedText = "";
        spans.forEach((span) => {
          combinedText += `${span.innerHTML} `;
        });
        combinedTexts.push(combinedText);
      } else {
        const pInner = paragraph.innerHTML;
        if (pInner) {
          combinedTexts.push(pInner);
        }
      }
    });
    tempDiv.innerHTML = "";

    combinedTexts.forEach((content) => {
      const newParagraph = document.createElement("p");
      const newSpan = document.createElement("span");
      newSpan.innerHTML = content;
      const firstSpan = paragraphs[0].querySelector("span");
      if (firstSpan) {
        newSpan.style.color = firstSpan.style.color;
        newSpan.style.backgroundColor = firstSpan.style.backgroundColor;
        newSpan.style.fontFamily = firstSpan.style.fontFamily;
      }
      newParagraph.appendChild(newSpan);
      tempDiv.appendChild(newParagraph);
    });
    desc = tempDiv.innerHTML;
  }
  return desc;
};

export const removePreLink = (link) =>
  link?.includes("https://") || link?.includes("http://")
    ? createValidUrl(link?.split(".net/")[1]?.split?.("?Policy")[0])
    : createValidUrl(link);
